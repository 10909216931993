import React, {
	forwardRef,
	useImperativeHandle,
	useRef,
	useState,
} from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import {
	InputField,
	SelectField,
	TextAreaField,
} from "../../../components/DesignSystem/FormField";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import ModalBox from "../../../components/DesignSystem/ModalBox";
import { addUser, approveBussinessUser } from "../../../action";
import NavTabs from "../../../components/DesignSystem/NavTabs";
import { adminNavItems, managerNavItems } from "../../../config/nav-config";
import { UserShema } from "../../../validationShema/UserShema";
import useIsAuthenticate from "../../../hooks/useIsAuthenticate";
import { filterObjectByValue, getMinValue } from "../../../util";
const optionsForGender = [
	{ value: "Male", label: "Male" },
	{ value: "Female", label: "Female" },
	{ value: "Other", label: "Other" },
];
const resData = {
	info: {
		first_name: "",
		last_name: "",
		gender: "",
		email: "",
		mobile_no: "",
		password: "",
		repassword: "",
		user_type: "C",
		address: "",
		abn_number: "",
		tfn_number: "",
	},
};
const ApproveBusinessUser = forwardRef(({}, ref) => {
	const { userType } = useIsAuthenticate();

	const [isEdit, setIsEdit] = useState(false);
	const [usrType, setUsrType] = useState("");
	const [currentTabItems, setCurrentTabItems] = useState([]);

	const dispatch = useDispatch();
	const openModal = useRef();
	const formOptions = {
		resolver: yupResolver(UserShema),
	};
	const {
		formState: { errors },
		handleSubmit,
		control,
		reset,
		setValue,
		getValues,
	} = useForm(formOptions, {
		defaultValues: {
			...resData,
		},
	});
	useImperativeHandle(ref, (item) => ({
		handleApproveBusinessUser(item, uType) {
			setValue("info.user_type", "C");

			if (item) {
				console.log({ item });
				setIsEdit(true);
				setCurrentTabItems([
					filterObjectByValue(adminNavItems, item.user_type),
				]);
				setUsrType(item.user_type);
				setValue("info", item);
				//setValue("info.gender", item?.gender);
			} else {
				reset(resData);
				//setCurrentTabItems(userType === "A" ? adminNavItems : managerNavItems);
				setCurrentTabItems([
					userType === "A"
						? filterObjectByValue(adminNavItems, uType)
						: filterObjectByValue(managerNavItems, uType),
				]);
				setIsEdit(false);
				setUsrType(uType);
				setValue("info.user_type", uType);
			}
			openModal.current.handleModalBox();
		},
	}));

	const getActiveNavValue = (activeUser) => {
		setValue("info.user_type", activeUser?.value);
		setUsrType(activeUser?.value);
	};

	const approveUser = (data) => {
		dispatch(approveBussinessUser(data));
		// reset({ ...resData });
	};
	return (
		<ModalBox ref={openModal} size="lg" title="View User Details">
			<Container>
				<div class="row gy-2 gx-2 ">
					<div className="p-2">
						<div style={{ backgroundColor: "#e9e9e9" }}>
							<NavTabs
								navItems={currentTabItems}
								getActiveNavValue={getActiveNavValue}
							/>
						</div>
						<div class="col-md-12">
							<div class="p-3 gap-2 card border rounded ">
								<div className="row">
									<div className="col-3 label">Name</div>
									<div className="col-1">:</div>
									<div className="col-8 label-text">
										{getValues("info.first_name")} {getValues("info.last_name")}
									</div>
								</div>

								<div className="row">
									<div className="col-3 label">Email</div>
									<div className="col-1">:</div>
									<div className="col-8 label-text">
										{getValues("info.email")}
									</div>
								</div>

								<div className="row">
									<div className="col-3 label">Mobile</div>
									<div className="col-1">:</div>
									<div className="col-8 label-text">
										{getValues("info.mobile_no")}
									</div>
								</div>
								{usrType === "D" && (
									<>
										{" "}
										<div className="row">
											<div className="col-3 label">Address</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.address")}
											</div>
										</div>
										<div className="row">
											<div className="col-3 label">ABN Number</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.abn_number")}
											</div>
										</div>
										<div className="row">
											<div className="col-3 label">TFN Nnumber</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.tfn_number")}
											</div>
										</div>
										<div className="col-md-12 mt-2">
											TFN Number : {getValues("info.tfn_number")}
										</div>
									</>
								)}

								<div className="row">
									<div className="col-3 label">Gender</div>
									<div className="col-1">:</div>
									<div className="col-8 label-text">
										{getValues("info.gender") &&
											JSON.parse(getValues("info.gender")).value}
									</div>
								</div>

								{getValues("info.customer_type") && (
									<>
										<div className="row">
											<div className="col-3 label">Customer Type</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.customer_type")}
											</div>
										</div>
										<div className="row">
											<div className="col-3 label">ABN Number</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.abn_number")}
											</div>
										</div>
										<div className="row">
											<div className="col-3 label">Business Name</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{getValues("info.business_name")}
											</div>
										</div>
										<div className="row">
											<div className="col-3 label">Director Names</div>
											<div className="col-1">:</div>
											<div className="col-8 label-text">
												{JSON.parse(getValues("info.director_names")).join(
													", "
												)}
											</div>
										</div>
									</>
								)}
								<div className="row">
									<div className="col-3 label">Status</div>
									<div className="col-1">:</div>
									<div className="col-8 label-text">
										{getValues("info.status") === "A" ? "Approved" : "Pending"}
									</div>
								</div>
							</div>
						</div>

						{getValues("info.customer_type") &&
							getValues("info.status") === "P" && (
								<div className="d-flex justify-content-end mt-3">
									<OutlineButton
										label="Approve User"
										onClick={() => approveUser(getValues("info"))}
									/>
								</div>
							)}
					</div>
				</div>
			</Container>
		</ModalBox>
	);
});
export default ApproveBusinessUser;
const Container = styled.div.attrs(() => ({
	className: "py-3 ",
}))`
	.header-title {
		text-transform: uppercase;
		letter-spacing: 0.02em;
		font-size: 14px;
		color: #6c757d;
	}
	.label {
		color: #6c757d;
		font-size: 14px;
		font-weight: 500;
	}
	.label-text {
		color: #6c757d;
		font-size: 13px;
	}
	.table-head {
		background-color: #eef2f7;
		-webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.12),
			0 1px 1px rgba(0, 0, 0, 0.24);
	}
	.table-body {
		overflow: scroll;
		overflow-x: hidden;
		max-height: 200px;
	}
`;
