import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchLowOutOfStockProducts } from "../../../action";
import { useMyNavigation } from "../../../hooks";

import {
  selectLowStockProductsInfo,
  selectOutOfStockProductsInfo,
} from "../../../redux/productSlice";

const stockList = [
  { item: " Chiken 2 Products Out of stock" },
  { item: " Chiken 2 Products Out of stock" },
  { item: " Chiken 2 Products Out of stock" },
  { item: " Chiken 2 Products Out of stock" },
];
const StockAlertBox = () => {
  const dispatch = useDispatch();
  const getLowStockInfo = useSelector(selectLowStockProductsInfo);
  const getOutOfStockInfo = useSelector(selectOutOfStockProductsInfo);
  const { push } = useMyNavigation();
  //console.log({ getOutOfStockInfo }, "====");

  useEffect(() => {
    dispatch(fetchLowOutOfStockProducts());
  }, [dispatch]);
  return (
    <Container>
      <div class=" px-3">
        <div class="row g-4">
          <div class="col-4">
            <div class=" border shadow-sm row-col">
              <div className="d-flex justify-content-between p-2">
                <div className="stock-title">Low Stock List</div>
                <a
                  href="javascript:void(0);"
                  className="view-all"
                  onClick={() => push("/inventory", { stockType: "L" })}
                >
                  View All
                </a>
              </div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between px-2 stock-subtitle">
                <div>Product</div> <div>Total Variant</div>
              </div>
              <div className="scroll-div" style={{ fontSize: 12 }}>
                <ul className="flex justify-content-between mt-2">
                  {getLowStockInfo.map(
                    ({ product_name, product_varients_for_stock_count }) => (
                      <li class="mb-2">
                        <div className="d-flex justify-content-between  ">
                          <div> {product_name}</div>
                          <div className="px-1">
                            {product_varients_for_stock_count}
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class=" border shadow-sm row-col">
              <div className="d-flex justify-content-between p-2">
                <div className="stock-title">Out Of Stock List</div>
                <a
                  href="javascript:void(0);"
                  className="view-all"
                  onClick={() => push("/inventory", { stockType: "O" })}
                >
                  View All
                </a>
              </div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between  stock-subtitle px-2 ">
                <div>Product </div> <div>Total Variant</div>
              </div>
              <div className="scroll-div" style={{ fontSize: 12 }}>
                <ul className="flex justify-content-between mt-2">
                  {getOutOfStockInfo.map(
                    ({ product_name, product_varients_for_stock_count }) => (
                      <li class="mb-2">
                        <div className="d-flex justify-content-between">
                          <div> {product_name}</div>
                          <div className="px-1 align-middle">
                            {product_varients_for_stock_count}
                          </div>
                        </div>
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default StockAlertBox;
const Container = styled.div`
  .row-col {
    min-height: 14rem;
  }
  margin-bottom: 20px;
  max-height: 15rem;
  min-height: 15rem;
  margin-top: 2rem;
  .stock-subtitle {
    font-size: 14px;
    color: #353535;
  }
  .stock-title {
    font-size: 18px;
    color: #353535;
    font-weight: 500;
  }

  .scroll-div {
    overflow: scroll;
    overflow-x: hidden;
    max-height: 10rem;
  }
  .view-all {
    color: #69a775;
    font-size: 15px;
    font-weight: 500;
  }
`;
