import React, { useEffect, useRef, useState } from "react";
import AGTable from "../../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";

import { Typography } from "../../../components/DesignSystem/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  fetchAllUserDetails,
  updateUserStatus,
} from "../../../action";
import { selectUsersDetailsInfo } from "../../../redux/userSlice";
import AddUser from "./AddUser";
import NavTabs from "../../../components/DesignSystem/NavTabs";
import { adminNavItems, managerNavItems } from "../../../config/nav-config";
import styled from "styled-components";
import useIsAuthenticate from "../../../hooks/useIsAuthenticate";
import ApproveBusinessUser from "./ApproveBusinessUser";

const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .ag-root-wrapper {
    border-radius: unset;
  }
`;
const Users = () => {
  const [user_type, setUserType] = useState("C");
  const addUserModal = useRef();
  const approveBusinessUserModal = useRef();
  const dispatch = useDispatch();
  const { userType } = useIsAuthenticate();

  const selectUsersDet = useSelector(selectUsersDetailsInfo);
  console.log(selectUsersDet, "selectUsersDet");
  useEffect(() => {
    dispatch(fetchAllUserDetails({ userType: user_type }));
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteUser({ id: id, userType: user_type }));
  };
  const changeStatus = (item = "") => {
    dispatch(
      updateUserStatus({
        id: item?.data?.id,
        status: item?.data?.status,
        userType: item?.data?.user_type,
      })
    );
  };
  const addUser = (activeUser) => {
    //console.log(user_type, "===");
    addUserModal.current.handleAddUser("", user_type);
  };
  const handleEdit = (item) => {
    addUserModal.current.handleAddUser(item);
  };
  const approveBusinessUser = (item) => {
    approveBusinessUserModal.current.handleApproveBusinessUser(item);
  };
  const getActiveNavValue = (activeUser) => {
    dispatch(fetchAllUserDetails({ userType: activeUser?.value }));
    setUserType(activeUser?.value);
  };
  return (
    <Container>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">User Listing</Typography>
        {/* {userType === "A" && ( */}
        <OutlineButton label="Add User" onClick={addUser} />
        {/* )} */}
      </div>
      <div style={{ backgroundColor: "#e9e9e9" }}>
        <NavTabs
          navItems={userType === "A" ? adminNavItems : managerNavItems}
          getActiveNavValue={getActiveNavValue}
        />
      </div>
      <AGTable
        handleEdit={handleEdit}
        handleView={approveBusinessUser}
        handleDelete={handleDelete}
        changeStatus={changeStatus}
        rowData={selectUsersDet}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        minWidth={200}
        optionalValue={{ field_value: "customer_type", value: "BUSINESS" }}
      />
      <AddUser ref={addUserModal} />
      <ApproveBusinessUser ref={approveBusinessUserModal} />
    </Container>
  );
};
export default Users;
