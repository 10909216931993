import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";
export const addOrder = createAsyncThunk(
	"orderSlice/addOrder",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "orderDetails",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0);
					if (object?.type === "A") dispatch(fetchOrderDetailsById(object?.id));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchOrderDetails = createAsyncThunk(
	"orderSlice/fetchOrderDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "orderDetails",
				filter: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchOrderReports = createAsyncThunk(
	"orderReportSlice/fetchOrderReports",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "getOrderReports",
				//filter: `type=${filter?.type}&&sDateOrYear=${filter?.sDateOrYear}&&eDate=${filter?.eDate}`,
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchOrderDetailsById = createAsyncThunk(
	"orderSlice/fetchOrderDetailsById",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "getById",
				endpoint: "orderDetails",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const ChangeOrderStatus = createAsyncThunk(
	"orderSlice/ChangeOrderStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "editOrderStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchOrderDetailsById(object?.id));
					if (object?.type === "M") dispatch(fetchOrderDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const ChangeOrderPaymentStatus = createAsyncThunk(
	"orderSlice/ChangeOrderPaymentStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "editOrderPaymentStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchOrderDetailsById(object?.id));
					if (object?.type === "M") dispatch(fetchOrderDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateOrderDeliveryAddress = createAsyncThunk(
	"orderSlice/updateOrderDeliveryAddress",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateOrderAddress",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchOrderDetailsById(object?.order_id));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
