import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	PlusIcon,
	CloseIcon,
	PhotoGalleryIcon,
	AddPhotoGalleryIcon,
} from "../../../components/DesignSystem/Icons/Icons";
import { useFieldArray, Controller } from "react-hook-form";
import {
	InputField,
	SelectField,
	TextAreaField,
} from "../../../components/DesignSystem/FormField";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
	clear,
	fetchGenricStateDetails,
	updateStateDetails,
} from "../../../redux/genricStateSlice";
import NotificationCount from "../../../components/DesignSystem/NotificationCount";
import { OutlineButton } from "../../../components/DesignSystem/Buttons";
import { Typography } from "../../../components/DesignSystem/Typography";
import { AVAILIBILITY } from "../../../Constant";

export const ProductVarient = ({
	control,
	errors,
	setValue,
	isEdit,
	viewPhotoGallery,
	getValues,
	addPhotoGallery,
	UNIT_TYPE,
}) => {
	const [flg, setFlg] = useState(true);
	const { fields, append, remove } = useFieldArray({
		control,
		name: "info.varientArray",
	});
	const dispatch = useDispatch();

	const getImages = useSelector(fetchGenricStateDetails);

	useEffect(() => {
		!isEdit && append();
	}, [append]);

	// const addPhotoGallery = useCallback(
	//   (indx) => imageRef.current.handleGallery(indx),
	//   []
	// );
	const renderComponent = () => {
		setFlg(!flg);
	};
	const handlePrice = (index, price) => {
		const discountValue = getValues(`info.varientArray.${index}.discount`);
		// console.log({ discountValue });
		setValue(`info.varientArray.${index}.discount`, 0);
		if (discountValue) {
			const sellingPrice = Math.round(price * ((100 - discountValue) / 100));
			setValue(`info.varientArray.${index}.selling_price`, sellingPrice);
		} else {
			setValue(`info.varientArray.${index}.selling_price`, price);
		}
	};
	const handleDiscount = (index, discountValue) => {
		const price = getValues(`info.varientArray.${index}.price`);
		if (price && discountValue <= 100) {
			const sellingPrice = Math.round(price * ((100 - discountValue) / 100));
			setValue(`info.varientArray.${index}.selling_price`, sellingPrice);
		}
	};
	const removeVarient = (index, id) => {
		const filterObject = Object.keys(getImages)
			.filter((key) => key !== id)
			.reduce((obj, key) => {
				obj[key] = getImages[key];
				return obj;
			}, {});
		dispatch(clear());
		dispatch(updateStateDetails(filterObject));
		remove(index);
	};
	console.log(getValues("info.is_available"), "-------------");
	return (
		<Container>
			<div>
				<div className="d-flex justify-content-between">
					<Typography size="18px">Product Variant</Typography>
					<span
						onClick={() => {
							append();
						}}
					>
						Add Variant
						<PlusIcon size={30} />
					</span>
				</div>
				{Boolean(fields?.length) ? (
					fields?.map((item, index) => {
						return (
							<div
								className="rounded border row px-1 py-1 mx-1 my-4 shadow-sm"
								key={item?.id}
								id={item?.id}
							>
								{fields?.length > 1 ? (
									<span
										className="cp text-end"
										onClick={() => removeVarient(index, item?.id)}
									>
										<CloseIcon size={25} />
									</span>
								) : (
									<div className="mt-4"></div>
								)}
								<div className="col-md-11">
									<div className="row">
										<div className="col-md-4">
											<Controller
												name={`info.varientArray.${index}.is_available`}
												control={control}
												render={({ field: { onChange, value, name, ref } }) => (
													<SelectField
														// isDisabled={
														//   getValues("info.is_available")?.value === 0
														// }
														//  defaultValue={AVAILIBILITY[0]}
														name={name}
														value={value}
														optionsItems={AVAILIBILITY}
														errors={errors}
														placeholder="Select Availibility"
														errorMessage="Please select availibility."
														ref={ref}
														onChange={onChange}
													/>
												)}
											/>
										</div>
										<div className="col-md-4">
											<Controller
												name={`info.varientArray.${index}.unit`}
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Unit"
														errorMessage="Enter Unit"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
										<div className="col-md-4">
											<Controller
												name={`info.varientArray.${index}.unit_type`}
												control={control}
												render={({ field: { onChange, value, name, ref } }) => (
													<SelectField
														name={name}
														value={value}
														optionsItems={UNIT_TYPE}
														errors={errors}
														placeholder="Unit type"
														errorMessage="Please select unit type."
														ref={ref}
														onChange={onChange}
													/>
												)}
											/>
										</div>

										<div className="col-md-3">
											<Controller
												name={`info.varientArray.${index}.price`}
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Price"
														errorMessage="Enter price"
														type="text"
														//onChange={onChange}
														onChange={(e) => {
															onChange(e);
															const priceValue = e?.target?.value;
															handlePrice(index, priceValue);
														}}
														ref={ref}
													/>
												)}
											/>
										</div>

										<div className="col-md-3">
											<Controller
												name={`info.varientArray.${index}.discount`}
												control={control}
												//rules={{ required: true }}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Discount%"
														errorMessage="Enter discount"
														type="text"
														onChange={(e) => {
															onChange(e);
															const discountValue = e?.target?.value;
															handleDiscount(index, discountValue);
														}}
														ref={ref}
													/>
												)}
											/>
										</div>

										<div className="col-md-3">
											<Controller
												name={`info.varientArray.${index}.selling_price`}
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														isReadonly={true}
														placeholder="Selling Price"
														errorMessage="Enter selling price"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>

										<div className="col-md-3">
											<Controller
												name={`info.varientArray.${index}.stock`}
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<InputField
														name={name}
														value={value}
														errors={errors}
														placeholder="Stock"
														errorMessage="Enter stock"
														type="text"
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>

										<div className="col-md-12 mb-3">
											<Controller
												name={`info.varientArray.${index}.description`}
												control={control}
												render={({ field: { onChange, name, value, ref } }) => (
													<TextAreaField
														name={name}
														value={value}
														errors={errors}
														placeholder="Description"
														errorMessage="Description"
														type="text"
														rules={{ required: true }}
														onChange={onChange}
														ref={ref}
													/>
												)}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-1">
									<div className="d-flex flex-column justify-content-center">
										{item?.product_id ? (
											<>
												<NotificationCount
													count={item?.varient_images?.length}
												/>
												<PhotoGalleryIcon
													size={33}
													onClick={() =>
														viewPhotoGallery(item?.vid, item?.product_id)
													}
												/>
											</>
										) : (
											<>
												<NotificationCount
													count={getImages[item?.id]?.length}
												/>
												<AddPhotoGalleryIcon
													size={33}
													onClick={() => {
														addPhotoGallery(item?.id);
														setValue(
															`info.varientArray.${index}.divids`,
															item?.id
														);
													}}
												/>
											</>
										)}
									</div>
								</div>
							</div>
						);
					})
				) : (
					<div className="mb-10" style={{ height: 200 }}></div>
				)}
			</div>
		</Container>
	);
};
const Container = styled.div.attrs(() => ({
	className: "mt-4",
}))`
	.form-control {
		height: 3rem;
	}
`;
