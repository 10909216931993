import styled from "styled-components";
import { EditIcon } from "../../../../components/DesignSystem/Icons/Icons";
import { CURRENCY } from "../../../../Constant";

const RecipientDetails = ({
  orderDetails: {
    total_amount,
    selling_price,
    discounted_amount,
    shipping_address,
    customer,
    payment_status,
    payment_method,
    order_status,
    created_at,
    order_details,
    id,
  },
  editStatusAddress,
}) => {
  const {
    receipient_name,
    receipient_contact_no,
    receipient_position,
    company_name,
    delivery_time,
    delevery_street_address,
    delivery_suburb,
    delivery_state,
    delivery_postal_code,
  } = shipping_address || {};
  const { full_name, mobile_no, email, customer_type } = customer || {};
  // order_details = [
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // 	{
  // 		item: "Poteto",
  // 		puchesed_quantity: 4,
  // 		price: 100,
  // 		puchesed_price: 200,
  // 	},
  // ];
  console.log({ order_details });
  return (
    <Container>
      <div class="row gy-2 gx-2 ">
        <div class="col-md-6">
          <div class="p-3 gap-2 card border rounded ">
            <h4 className="header-title">Recipient Info</h4>
            <div className="row">
              <div className="col-3 label">Name</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{receipient_name}</div>
            </div>

            <div className="row">
              <div className="col-3 label">Contact</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{receipient_contact_no}</div>
            </div>

            <div className="row">
              <div className="col-3 label">Position</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{receipient_position}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-3 gap-2 card border rounded ">
            <h4 className="header-title">Customer Info</h4>
            <div className="row">
              <div className="col-3 label">Name</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{full_name}</div>
            </div>
            <div className="row">
              <div className="col-3 label">Mobile</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{mobile_no}</div>
            </div>
            <div className="row">
              <div className="col-3 label">Email</div>
              <div className="col-1">:</div>
              <div className="col-8 label-text">{email}</div>
            </div>
            {customer_type === "BUSINESS" && (
              <div className="row">
                <div className="col-3 label">Customer Type</div>
                <div className="col-1">:</div>
                <div className="col-8 label-text">{customer_type}</div>
              </div>
            )}
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-3 gap-2 card border rounded ">
            <h4 className="header-title ">Acountent Info</h4>
            <div className="row ">
              <div className="col-5 label">Company Name</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">{company_name}</div>
            </div>
            <div className="row ">
              <div className="col-5 label">Name</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">{email}</div>
            </div>
            <div className="row">
              <div className="col-5 label">Email</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">{email}</div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="p-3 gap-3 card border rounded ">
            <h4 className="header-title">Delivery Info</h4>

            <div className="row">
              <div className="col-5 label">Delivery Status</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">
                {order_status}{" "}
                <span
                  className="cp"
                  onClick={() => editStatusAddress(order_status, id, "S")}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-5 label">Expected Time</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">{delivery_time}</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-3  card border rounded">
            <h4 className="header-title mb-3">Delivery Address</h4>
            <div className="label-text mb-2">{delevery_street_address}</div>
            <div className="label-text d-flex gap-2">
              {delivery_suburb}, {delivery_state}
              <span
                className="cp"
                onClick={() => editStatusAddress(shipping_address, id, "A")}
              >
                <EditIcon />
              </span>
            </div>
            <div className="label-text">{delivery_postal_code}</div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-3 gap-3 card border rounded ">
            <h4 className="header-title">Payment Info</h4>
            <div className="row">
              <div className="col-5 label">Payment Status</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">
                {payment_status}{" "}
                <span
                  className="cp"
                  onClick={() => editStatusAddress(payment_status, id, "PS")}
                >
                  <EditIcon />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-5 label">Payment Method</div>
              <div className="col-1">:</div>
              <div className="col-6 label-text">{payment_method} </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="p-3 gap-2 card border rounded ">
            <h4 className="header-title ">ITEMS FROM ORDER</h4>{" "}
            <div className="row m-1 p-2 table-head">
              <div className="col-6">Item</div>
              <div className="col-2">Quantity</div>
              <div className="col-2">Price</div>
              <div className="col-2">Total</div>
            </div>
            {order_details?.map(
              ({
                product_details,
                puchesed_quantity,
                puchesed_price,
                total_price,
              }) => (
                <>
                  <div className="row mx-3 ">
                    <div className="col-6">{product_details?.product_name}</div>
                    <div className="col-2">{puchesed_quantity}</div>
                    <div className="col-2">
                      {" "}
                      {CURRENCY}
                      {puchesed_price}
                    </div>
                    <div className="col-2">
                      {" "}
                      {CURRENCY}
                      {total_price}
                    </div>
                  </div>
                  <div className="divider"></div>
                </>
              )
            )}
            <div className="row mx-3 mt-4 label-text">
              <div className="col-6"></div>
              <div className="col-2"></div>
              <div className="col-2">Selling Price</div>
              <div className="col-2">
                {CURRENCY}
                {selling_price}
              </div>
            </div>
            <div className="row mx-3 label-text">
              <div className="col-6"></div>
              <div className="col-2"></div>
              <div className="col-2">Discount</div>
              <div className="col-2">
                -{CURRENCY}
                {discounted_amount ? discounted_amount : 0}
              </div>
            </div>
            <div className="divider"></div>
            <div className="row mx-3 " style={{ fontWeight: "bold" }}>
              <div className="col-6"></div>
              <div className="col-2"></div>
              <div className="col-2 ">Total Amount</div>
              <div className="col-2">
                {CURRENCY}
                {total_amount}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default RecipientDetails;

const Container = styled.div.attrs(() => ({
  className: "py-3 ",
}))`
  .header-title {
    text-transform: uppercase;
    letter-spacing: 0.02em;
    font-size: 14px;
    color: #6c757d;
  }
  .label {
    color: #6c757d;
    font-size: 14px;
    font-weight: 500;
  }
  .label-text {
    color: #6c757d;
    font-size: 14px;
  }
  .table-head {
    background-color: #eef2f7;
    -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.12),
      0 1px 1px rgba(0, 0, 0, 0.24);
  }
  .table-body {
    overflow: scroll;
    overflow-x: hidden;
    max-height: 200px;
  }
`;
