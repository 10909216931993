import { userType } from "../config/nav-config";
import { filterObjectByValue } from "../util";
import { AVAILIBILITY, CURRENCY } from "./../Constant/index";

export const userDetailsNormalizer = (items) => {
	return items?.map((item) => {
		return {
			...item,
			name: `${item?.first_name} ${item?.last_name}`,
			userType: userType[item?.user_type],
			mobile_no: item?.mobile_no == "" ? "N/A" : item?.mobile_no,
		};
	});
};
export const sellingProductsNormalizer = (items) => {
	return items?.map((item) => {
		return {
			...item,
			orderNo: item?.order_details?.order_no,
			createdDate: item?.order_details?.created_at,
			productName: item?.product_details?.product_name,
			productImage: item?.product_details?.image,
			varientQuantity: item?.varient_details?.quantity,
			varientSellingPrice: `${CURRENCY}${item?.varient_details?.selling_price}`,
			total_price: `${CURRENCY}${item?.total_price}`,
		};
	});
};
export const categoryNormalizer = (items, isSubCat = false) => {
	let filterItems = [];
	if (isSubCat) {
		filterItems = items.filter(
			(item) => item?.lb === 2 && item?.parent != null
		);
	} else {
		filterItems = items.filter(
			(item) => item?.lb === 1 && item?.parent != null
		);
	}

	return filterItems?.map((item) => {
		return {
			...item,
			parent_category: item?.parent?.category_name,
		};
	});
};
export const categoryNormalizers = (
	items,
	isSubCat = false,
	parent = false
) => {
	let filterItems = [];
	if (isSubCat) {
		filterItems = items.filter(
			(item) => item?.lb === 2 && item?.parent != null
		);
	} else if (parent) {
		filterItems = items.filter((item) => item?.p_id === 0);
	} else {
		filterItems = items.filter((item) => item?.lb === 1);
	}
	return filterItems?.map((item) => {
		return {
			...item,
			parent_category: item?.parent?.category_name,
		};
	});
};
export const notificationNormalize = (items) => {
	return items?.map((item) => {
		return {
			...item,
			type: item?.notification_type === "C" ? "Customer" : "Rider",
		};
	});
};

export const getOptionValueFormatForCategories = (items) => {
	return items.map((item) => {
		return {
			value: item.id,
			label: item.category_name,
		};
	});
};
export const lowStockNormalizer = (items) => {
	const filterData = items?.filter(
		({ varients }) =>
			Object.keys(varients).length !== 0 &&
			Object.values(varients).some(function (k) {
				return k.stock !== 0;
			})
	);
	return (
		filterData?.map((element) => {
			return {
				...element,
				varients: element.varients
					.map((varient) => {
						return {
							...varient,
							price: `${CURRENCY}${varient?.price}`,
							selling_price: `${CURRENCY}${varient?.selling_price}`,
						};
					})
					.filter((varient) => varient.stock > 0),
			};
		}) || []
	);
};
//  !Object.values(varients).some(function (k) {
//    return k == null;
//  });
export const outOfStockNormalizer = (items) => {
	const filterData = items?.filter(
		({ zero_stock_varients }) =>
			Object.keys(zero_stock_varients).length !== 0 &&
			Object.values(zero_stock_varients).some(function (k) {
				return k.stock === 0;
			})
	);
	return (
		filterData?.map((element) => {
			return {
				...element,
				varients: element.zero_stock_varients
					.map((zero_stock_varient) => {
						return {
							...zero_stock_varient,
							price: `${CURRENCY}${zero_stock_varient?.price}`,
							selling_price: `${CURRENCY}${zero_stock_varient?.selling_price}`,
						};
					})
					.filter((zero_stock_varient) => zero_stock_varient.stock === 0),
			};
		}) || []
	);
};

export default function productDetailsNormalizer(item) {
	return {
		...item,
		unit_type: (item?.unit_type && JSON.parse(item?.unit_type)) || "",
		edit_product_varients: item?.edit_product_varients?.map((varient) => {
			return {
				...varient,
				is_available: filterObjectByValue(AVAILIBILITY, varient?.is_available),
			};
		}),
	};
}
export const orderNormlize = (items) => {
	return items.map((item) => {
		return {
			...item,
			total_amount: `${CURRENCY}${item?.total_amount}`,
		};
	});
};
