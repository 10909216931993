import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
// import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import TostAction from "./toastAction";
import { dispatchErrorToastAction } from "./commonFunction";

export const adminLogin = createAsyncThunk(
	"userSlice/adminLogin",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = { method: "post", endpoint: "userInfo", object: object };

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					if (res?.token) {
						localStorage.setItem("access_token", res?.token);
						dispatch(fetchUserDetails());
					}
					TostAction(dispatch, res, 1, 0, 0, 1);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const deleteRequest = createAsyncThunk(
	"userSlice/deleteRequest",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteRequest",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchUserDetails = createAsyncThunk(
	"user/fetchUserDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "adminUser",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const fetchAllUserDetails = createAsyncThunk(
	"user/fetchAllUserDetails",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "getUsersDetails",
				object: object,
			};
			// const param = {
			// 	method: "get",
			// 	endpoint: "getUsersDetails",
			// 	id: queryparam,
			// };

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const addUser = createAsyncThunk(
	"userSlice/addUser",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "user",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchAllUserDetails({ userType: object?.user_type }));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteUser = createAsyncThunk(
	"userSlice/deleteUser",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteUser",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchAllUserDetails({ userType: object?.userType }));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateUserStatus = createAsyncThunk(
	"userSlice/updateUserStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateUserStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchAllUserDetails({ userType: object?.userType }));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const approveBussinessUser = createAsyncThunk(
	"userSlice/approveBussinessUser",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "approveBussinessUser",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 1, 0, 0, 1);
					dispatch(fetchAllUserDetails({ userType: object?.user_type }));
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchUserTypeDetails = createAsyncThunk(
	"user/fetchUserTypeDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "fetchUserType",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
