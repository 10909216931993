import React, { useMemo, useState } from "react";
const getDate = (value) => {
	var dateParts = value.split("/");
	return new Date(
		Number(dateParts[2]),
		Number(dateParts[1]) - 1,
		Number(dateParts[0])
	);
};
// {params.value}

const dateFilterParams = {
	filters: [
		{
			filter: "agDateColumnFilter",
			filterParams: {
				comparator: (filterDate, cellValue) => {
					if (cellValue == null) return -1;
					return getDate(cellValue).getTime() - filterDate.getTime();
				},
			},
		},
		{
			filter: "agSetColumnFilter",
			filterParams: {
				comparator: (a, b) => {
					return getDate(a).getTime() - getDate(b).getTime();
				},
			},
		},
	],
};
export const columnDefs = [
	{
		headerName: "Name",
		field: "name",
		filter: "agMultiColumnFilter",
	},
	{
		headerName: "Email",
		field: "email",
		filter: "agMultiColumnFilter",
	},
	{
		field: "MobileNo",
		field: "mobile_no",
		filter: "agMultiColumnFilter",
	},
	{
		field: "User Type",
		cellRenderer: (params) => {
			return <>{params.data.userType}</>;
		},
	},

	{
		headerName: "Created Date",
		field: "created_at",
		filter: "agMultiColumnFilter",
		filterParams: dateFilterParams,
	},
	{
		field: "Created By",
		cellRenderer: (params) => {
			return <>{params.data.is_admin === 1 ? "Admin" : "Customer"}</>;
		},
	},
];
export const defaultColDef = {
	flex: 1,
	minWidth: 184,
	menuTabs: ["filterMenuTab"],
};
