import * as Yup from "yup";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

export const ProductShema = Yup.object().shape({
	info: Yup.object().shape({
		product_name: Yup.string().required(""),
		quantity_alert: Yup.number().required(""),
		// product_description: Yup.string().required(""),
		product_category: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),
		category: Yup.object().shape({
			value: Yup.string().required("Required"),
			label: Yup.string().required("Required"),
		}),

		// varientArray: Yup.array(
		// 	Yup.object({
		// 		unit: Yup.number()
		// 			.positive("Must be a positive value")
		// 			.min(0, "must be greater than or equal to 1!")
		// 			.required("Required"),
		// 		price: Yup.number()
		// 			.positive("Must be a positive value")
		// 			.min(0, "must be greater than or equal to 0!")
		// 			.required("Required"),
		// 		discount: Yup.number()
		// 			.positive("Must be a positive value")
		// 			.min(0, "must be greater than or equal to 0!")
		// 			.required("Required"),
		// 		selling_price: Yup.number()
		// 			.positive("Must be a positive value")
		// 			.min(0, "must be greater than or equal to 0!")
		// 			.required("Required"),
		// 		stock: Yup.number()
		// 			.positive("Must be a positive value")
		// 			.min(0, "must be greater than or equal to 0!")
		// 			.required("Required"),
		// 		description: Yup.string().required("Required"),
		// 	})
		// ).required("Required"),
		varientArray: Yup.array().of(
			Yup.object().shape({
				unit: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.required("Required"),
				unit_type: Yup.object().shape({
					value: Yup.string().required("Required"),
					label: Yup.string().required("Required"),
				}),
				is_available: Yup.object().shape({
					value: Yup.string().required("Required"),
					label: Yup.string().required("Required"),
				}),

				price: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.test("is_available", "Price is required", function (value) {
						const val = this.parent.is_available?.value;
						//console.log({ val });
						if (val === "1") {
							if (!value) {
								return this.createError({
									message: "Price is required",
								});
							}
							return true;
						} else {
							return true;
						}
					}),
				discount: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.test("is_available", "Discount is required", function (value) {
						const val = this.parent.is_available?.value;

						if (val === "1") {
							console.log({ value: !value });
							if (value < 0) {
								return this.createError({
									message: "Discount is required",
								});
							}
							return true;
						} else {
							return true;
						}
					}),
				selling_price: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.test("is_available", "Selling price is required", function (value) {
						const val = this.parent.is_available?.value;

						if (val === "1") {
							if (!value) {
								return this.createError({
									message: "Selling price is required",
								});
							}
							return true;
						} else {
							return true;
						}
					}),
				stock: Yup.number()
					.positive("Must be a positive value")
					.min(0, "must be greater than or equal to 1!")
					.test("is_available", "Stock is required", function (value) {
						const val = this.parent.is_available?.value;
						if (val === "1") {
							if (value < 0) {
								return this.createError({
									message: "Stock is required",
								});
							}
							return true;
						} else {
							return true;
						}
					}),
				description: Yup.string().required("Required"),
			})
		),
	}),
});
